import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql, navigate } from "gatsby";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import bullhorn from "../../images/bullhorn_small.png";
import Modal from "@material-ui/core/Modal/Modal";
import ReactHtmlParser, {processNodes} from "react-html-parser";
import Link from "../Link";

const useStyles = makeStyles(theme => ({
    alertModal: {
        '& .alert-modal': {
            border: 'none !important',
            outline: 'none !important',
            backgroundColor: theme.palette.secondary.main,
            position: 'absolute',
            top: 100,
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '25px 50px',
            width: 'calc(100vw - 30px)',
            maxWidth: 565,
            [theme.breakpoints.up('md')]: {
                top: 200,
                padding: '40px 90px',
            },
            '& .bullhorn': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 30,
                '&::before, &::after': {
                    height: 1,
                    backgroundColor: theme.palette.primary.main,
                    content: "''",
                    display: 'block',
                    flex: '0 0 calc(50% - 31px)',
                },
                '& img': {
                    margin: '0 20px',
                }
            },
            '& p, & li': {
                color: theme.palette.secondary.contrastText,
                lineHeight: 1.5,
                '& a': {
                    color: theme.palette.secondary.contrastText,
                }
            },
            '& button': {
                color: theme.palette.secondary.contrastText,
                fontSize: 16,
                letterSpacing: '0.016em',
                lineHeight: 1.5,
                margin: '35px auto 0',
                display: 'block',
                verticalAlign: 'middle',
                backgroundColor: 'transparent',
                border: 'none',
                fontFamily: theme.headerFont,
                textTransform: 'uppercase',
                '&:hover': {
                    cursor: 'pointer',
                },
                '& span': {
                    backgroundColor: theme.palette.secondary.contrastText,
                    width: 18,
                    height: 18,
                    clipPath: 'polygon(7px 1px, 8px 0px, 10px 0px, 11px 1px, 11px 7px, 17px 7px, 18px 8px, 18px 10px, 17px 11px, 11px 11px, 11px 17px, 10px 18px, 8px 18px, 7px 17px, 7px 11px, 1px 11px, 0px 10px, 0px 8px, 1px 7px, 7px 7px)',
                    display: 'inline-block',
                    verticalAlign: 'text-top',
                    transform: 'rotate(45deg)',
                    marginLeft: 3,
                }
            }
        }
    },
}));

export default function GlobalAlert() {

  const classes = useStyles();

    const [open, setOpen] = React.useState(true);

  const data = useStaticQuery(graphql`
      query globalAlert {
        wp {
          alertBox {
            AlertBox {
              fieldGroupName
              showAlert
              alertContent
            }
          }
        }
      }
    `);

    const isBrowser = typeof window!==`undefined`;

    const hasSeen = () => {
        if(isBrowser){
            return window.sessionStorage.getItem('hasSeenGlobalAlert');
        } else {
            return true;
        }
    }

    const handleClose = () => {
        setOpen(false);

        if(isBrowser) {
            window.sessionStorage.setItem('hasSeenGlobalAlert', true);
        }
    };

    const closeLink = (e, link) => {
        e.preventDefault();
        handleClose();
        navigate(link);
    }

    const modalCloseLink = (node) => {
        // Convert a tags to links that handle routing
        if(node.type ==='tag' && node.name === 'a'){
            // Replace the admin URL in the link if set
            const link = node.attribs.href;
            return <Link key={link} to={link} onClick={(e) => closeLink(e, link)}>{processNodes(node.children, modalCloseLink)}</Link>
        }
    };

    if(hasSeen() === null && data.wp.alertBox.AlertBox.showAlert === true) {
        return (
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.alertModal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className="alert-modal">
                    <span className="bullhorn">
                        <img src={bullhorn} alt=""/>
                    </span>
                    {ReactHtmlParser(data.wp.alertBox.AlertBox.alertContent, {transform : modalCloseLink})}
                    <button type="button" onClick={handleClose}>Close <span/></button>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
}